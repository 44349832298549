// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: "Fiserv: Facilities",
  filename: 'environment.facilities-7073.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: false,
    usernamePassword: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyCurK4Y5qDHz2zLmnG-kztzC97GHYKiWSA",
    authDomain: "facilities-7073.firebaseapp.com",
    databaseURL: "https://facilities-7073-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-7073",
    storageBucket: "facilities-7073.appspot.com",
    messagingSenderId: "548167776398",
    appId: "1:548167776398:web:39d6713a5eea15efa4b1df"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-7073.cloudfunctions.net',


  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,

  },

  preview: {

    clusterSupport: true,
    fireworksEnabled: true,
    reportGeneration2: true,
  },

};
